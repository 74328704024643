.card-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}
.card-container div{
  margin: 5px;
}

.table-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}

  